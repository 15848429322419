.form-container {
    height: 100vh;
    position: relative; /* Adicionado */
  }
  
  .form-signin {
    width: 330px;
    padding: 15px;
    margin: auto;
  }
  
  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .login-links a {
    text-decoration: none;
    font-size: 10pt;
  }
  
  .imglogo {
    width: 100px;
  }