.icone-acao{
    margin-left: 7px;
    margin-right: 7px;
}

.red{
    color: rgb(194, 74, 74);
}

.col-acao{
    width: 80px;
}

.form-group{
    padding: 10px;
}

.salvar{
    margin-bottom: 2%;
}

.selected-image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .add-image-button {
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  
  .image-upload-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-top: -25px;
    margin-bottom: -25px;
    border: 2px dashed #ccc;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
  }

  .btn-xml{
    margin-left: 92% !important ;
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow-y: auto;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    overflow-y: auto;
  }
  


  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover {
    color: black;
  }

  .btn-novo {
    position: fixed; /* Alterado para 'fixed' para fixar o botão na tela */
    bottom: 20px; /* Distância a partir do rodapé */
    left: 3px; /* Distância a partir da borda esquerda */
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 7px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }