.navbar{
    background-color: #0d6efd;
    padding: 1%;
}

.navbar-admin {
    position: fixed;
    margin-top: -22px;
    margin-left: 71%;
}

.navbar .logout{
    cursor: pointer;
}

.navbar-finalizar {
    position: fixed;
    margin-top: -22px;
    margin-left: 82%;
}

/* Estilo para a sobreposição do modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Estilo para o modal em si */
  .modal-finalizar {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-left: 4%;
    margin-bottom: 0%;
    padding: 20px;
    max-width: 400px;
    width: 100%;
  }

  .x-fechar {
    margin-block-start: -50px;
  }
  
  /* Estilo para o título do modal */
  .modal h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  /* Estilo para os campos de entrada do formulário */
  .form-control-modal {
    width: 100%;
    padding: 10px;
    margin-bottom: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Estilo para os botões do modal */
  .modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  /* Estilo para o botão "Salvar" */
  .btn-primary-modal {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Estilo para o botão "Fechar" */
  .btn-danger-modal {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Estilo para o botão "Logout" */
  .btn-logout-modal {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Estilo para o alerta de sucesso */
  .alert-success {
    background-color: #228639;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .close-icon {
    position: absolute;
    top: 82px;
    right: 460px;
    cursor: pointer;
    font-size: 17px;
    color: #535151;
  }

  .close-icon-2 {
    position: absolute;
    top: 267px;
    right: 460px;
    cursor: pointer;
    font-size: 17px;
    color: #535151;
  } 

  .nav-link[title]:hover:after {
    content: attr(title);
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 1;
    pointer-events: none;
    font-size: 12px;
  }  
  
  