.opcoes-admin {
    margin: 20px;
    margin-top: 80px;
  }

  .titulo-admin {
    font-size: 24px;
  }

  .btn-voltar {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 7px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  

  .btn-voltar i {
    margin-right: 5px;
  }

  .btn-voltar:hover {
    background-color: #0516ad;
  }
  
  .table-admin {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-admin th {
    background-color: #f2f2f2;
    text-align: left;
    padding: 10px;
    font-weight: bold;
    border: 1px solid #ccc;
  }
  
  .table-admin td {
    padding: 10px;
    border: 1px solid #ccc;
  }

  .table-admin td a {
    text-decoration: none;
    color: #007bff;
  }

  .table-admin td a:hover {
    text-decoration: underline;
  }

  .table-admin:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table-admin tr:hover {
    background-color: #f5f5f5;
  }

  .table-admin td:hover {
    background-color: #f0f0f0;
  }

  .table-admin th:hover {
    background-color: #f2f2f2;
  }

  .aba {
    margin-top: 5%;
 }  
  