.edit-cliente-container {
    display: flex;
    align-items: center;
  }
  
  .edit-cliente-image {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 20px;
  }
  
  .edit-cliente-actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .btn-excluir-foto {
    background-color: #dc3545;
    border-color: #dc3545;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .btn-excluir-foto:hover {
    background-color: #c82333;
  }
  
  .btn-excluir-foto:focus {
    background-color: #c82333;
  }
  
  .btn-excluir-foto:active {
    background-color: #bd2130;
  }
  
  /* Ajuste do estilo do botão Excluir Foto */
  @media (max-width: 576px) {
    .edit-cliente-actions {
      align-items: flex-start;
    }
  }
  
.buttons{
    margin-bottom: 5%;
}