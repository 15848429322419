.titulo {
    margin-top: 80px;
  }
  
  .input-group {
    margin-left: 51%;
  }
  
  .foto-cliente-modal {
    width: 130px;
    height: 130px;
    margin-left: 5%;
    border: 2px solid #ccc;
    border-radius: 10%;
    float: left;
  }
  
  .modal_bory {
    display: flex;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    height: 50%;
  }
  
  .titleModal {
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 5%;
  }
  
  .modal-content-cliente {
    background-color: #fefefe;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    max-height: 250px;
    overflow-y: auto;
    z-index: 2;
    margin-left: 25%;
  }
  
  .dados_cliente {
    padding: 10px;
    margin-left: 40%;
  }
  